import React, { useEffect, useState } from 'react';
import { getClientsForall,getAllEmployees } from '../services/api';  // Assuming you have an API function to fetch clients
import { FaBomb, FaShieldAlt, FaThumbsUp } from 'react-icons/fa';  // Importing alternative icons

const ChildrenTable = () => {
  const [clients, setClients] = useState([]);
   const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [childrenCount, setChildrenCount] = useState(0); // Count of children close to turning 15
  const [sortedChildren, setSortedChildren] = useState([]); // Sorted children data


      useEffect(() => {
          const fetchEmployees = async () => {
              try {
                  const response = await getAllEmployees();
                 
                  setEmployees(response.data); // Set employees in state
              } catch (err) {
                  console.error('Error fetching employees:', err);
                 
              }
          };
          fetchEmployees(); // Call fetch function
      }, []); // Run on mount only
  
    
  // Fetch clients and their children data
  const getClients = async () => {
    setLoading(true);
    try {
      const response = await getClientsForall();  // Assuming this returns an array of clients with children
      setClients(response.data);
    } catch (err) {
      setError('Failed to fetch clients. Please check your permissions.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  // Function to calculate the number of months until the child turns 15
  const getMonthsToFifteen = (birthdate) => {
    const today = new Date();
    const birthday = new Date(birthdate);
    const ageInMonths = (today.getFullYear() - birthday.getFullYear()) * 12 + (today.getMonth() - birthday.getMonth());
    return 180 - ageInMonths; // 180 months = 15 years
  };

  // Function to determine the level (1, 2, or 3 months away)
  const getLevel = (monthsToFifteen) => {
    if (monthsToFifteen > 0 && monthsToFifteen <= 1) {
      return { level: 'Warning', color: 'red', icon: <FaBomb style={{ color: 'red' }} /> };  // Bomb icon for warning
    } else if (monthsToFifteen > 0 && monthsToFifteen <= 2) {
      return { level: 'Level 2 (2 months)', color: 'orange', icon: <FaShieldAlt style={{ color: 'orange' }} /> };  // Shield icon for moderate caution
    } else if (monthsToFifteen > 0 && monthsToFifteen <= 3) {
      return { level: 'Level 3 (3 months)', color: 'green', icon: <FaThumbsUp style={{ color: 'green' }} /> };  // Thumbs up for everything good
    } else {
      return null;  // More than 3 months, don't display
    }
  };

  const getName = (id) => {
    if (employees && Array.isArray(employees)) {
     
      const selectedEmployee = employees.find(emp => emp.id == id);  // Make sure id is compared correctly

  
      if (selectedEmployee) {
        const name = selectedEmployee.username.split("@")[0];
        return name;
      } else {
      
      }
    } else {
    
    }
    return null;  // Return null or some fallback if no employee is found
  };
  // This effect will run after `clients` data is fetched and updated.
  useEffect(() => {
    if (clients.length > 0) {
      const allChildren = [];

      // Collect children and filter by months to 15
      clients.forEach(client => {
        if (client.children && Array.isArray(client.children)&&(client.is_worker==0)) {
          client.children.forEach(child => {
            if (!child || !child.birthdate) {
                return null;  // Skip this child if no valid birthdate
              }


            const monthsToFifteen = getMonthsToFifteen(child.birthdate);

            if (monthsToFifteen > 0 && monthsToFifteen < 180) {
              const levelDetails = getLevel(monthsToFifteen);
              if (levelDetails) {
                allChildren.push({ child, client, monthsToFifteen, levelDetails });
              }
            }
          });
        }
      });

      // Sort children by the months to 15 (ascending order, so closer ones come first)
      const sortedChildrenList = allChildren.sort((a, b) => a.monthsToFifteen - b.monthsToFifteen);

      setChildrenCount(sortedChildrenList.filter(a => a.monthsToFifteen==1).length);  // Set the total count of children
      setSortedChildren(sortedChildrenList);  // Set the sorted children
    }
  }, [clients]); // Runs when `clients` data is updated

  return (
    <div className="container mt-4">
      <h2>Children Close to Turning 15</h2>
      {loading && <p>Loading...</p>}
      {error && <p className="text-danger">{error}</p>}

      {/* Apply max-height and overflow to make table scrollable */}
      <h3 style={{fontSize:"1rem", color:"black"}}>Children this month to be 15 <span style={{fontSize:"1rem", color:"red"}}>(Count: {childrenCount})</span></h3>
      <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
        <table className="table table-striped table-responsive">
          <thead style={{ color: 'white', backgroundColor: '#343a40' }}>
            <tr className="child-table">

              <th>Mitgliededer</th>
              <th>Name</th>
              <th>Father's Name (Client)</th>
              <th>Birthdate</th>
              <th>Months to Turn 15</th>
              <th>Level</th>
            </tr>
          </thead>
          <tbody>
            {sortedChildren.map((childData, index) => {
              const { child, client, levelDetails } = childData;
              const { icon, color } = levelDetails;

              return (
                <tr
                  key={index}
                  style={{
                    backgroundColor:
                      color === 'red' ? '#f8d7da' : color === 'orange' ? '#fff3cd' : '#d4edda',
                  }}
                >
           <td className='emp_field'>{getName(client.employee_id)}</td>
                  <td style={{ textAlign: 'center' }}>
                    {child.child_vorname} {child.child_nachname}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {client.nachname} {client.vorname}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {new Date(child.birthdate).toLocaleDateString()}
                  </td>
                  <td style={{ textAlign: 'center' }}>{childData.monthsToFifteen}</td>
                  <td style={{ textAlign: 'center' }}>{icon}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ChildrenTable;
