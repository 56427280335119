import React, { useState, useEffect } from 'react';
import { Modal, Button, ListGroup, Form, Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Masonry from 'react-masonry-css';

import {getAll,updateUserPassword,deleteUser,register} from '../../services/api';

const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1
  };
  
const ChangePass = () => {
    const [newUsername, setNewUsername] = useState(''); // State for new user input
    const [newUserPassword, setNewUserPassword] = useState(''); // State for new user password
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [newPassword, setNewPassword] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState({ type: '', text: '' });

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const usersData = await getAll();
                setUsers(usersData.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchUsers();
    }, []);

    const handleOpenAddUserModal = () => {
        setShowAddUserModal(true);
    };

    const handleCloseAddUserModal = () => {
        setNewUsername('');
        setNewUserPassword('');
        setShowAddUserModal(false);
    };

    const handleOpenModal = (user) => {
        setSelectedUser(user);
        setNewPassword('');
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedUser(null);
        setShowModal(false);
    };

    const handleChangePassword = async () => {
        if (!newPassword) {
            setMessage({ type: 'danger', text: 'Please enter a new password.' });
            return;
        }
        try {
            await updateUserPassword(selectedUser.id, newPassword);
            setMessage({ type: 'success', text: 'Password updated successfully.' });
            handleCloseModal();
        } catch (error) {
            console.log(error)
            setMessage({ type: 'danger', text: 'Error updating password.' });
        }
    };

    const handleDeleteUser = async () => {
        const confirmDelete = window.confirm(`Are you sure you want to delete ${selectedUser.username}? This action cannot be undone.`);
        
        if (!confirmDelete) {
            return; // Stop the function if the user cancels
        }
    
        try {
            await deleteUser(selectedUser.id);
            setUsers(users.filter(user => user.id !== selectedUser.id));
            setMessage({ type: 'success', text: 'User deleted successfully.' });
            handleCloseModal();
        } catch (error) {
            console.log(error);
            setMessage({ type: 'danger', text: 'Error deleting user.' });
        }
    };
    const handleAddNewUser = async () => {
        if (!newUsername || !newUserPassword) {
            setMessage({ type: 'danger', text: 'Please fill in all fields.' });
            return;
        }
        try {
            await register(newUsername,newUserPassword);
            setUsers([...users, { username: newUsername, password: newUserPassword }]); // Update user list
            setMessage({ type: 'success', text: 'User added successfully.' });
            handleCloseAddUserModal();
        } catch (error) {
            console.log(error);
            setMessage({ type: 'danger', text: 'Error adding user.' });
        }
    };


    return (
        <div className='container mt-4'>
            <h2 className='mb-4'>Manage Users</h2>
            <Button style={{width:"25%"}} variant="primary" className="mb-4" onClick={handleOpenAddUserModal}>
                Add New User +
            </Button>

            {message.text && <div className={`alert alert-${message.type}`}>{message.text}</div>}
            <ListGroup>
    <Row>
        {users.map((user, index) => (
            <Col md={6} key={user.id} className="mb-2">
                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                    <span style={{fontSize:"0.82rem"}}>{user.username}{user.role=="admin"?<span style={{color:"red", fontWeight:"bold"}}> -Admin</span>:""}</span>
                    <Button style={{width:"30%", fontSize:"0.8rem"}} variant="primary" onClick={() => handleOpenModal(user)}>Edit/Delete</Button>
                </ListGroup.Item>
            </Col>
        ))}
    </Row>
</ListGroup>


            {/* Modal for editing or deleting user */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Manage User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedUser && (
                        <>
                            <Form.Group className='mb-3'>
                                <Form.Label>Username</Form.Label>
                                <Form.Control type='text' value={selectedUser.username} disabled />
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    type='password'
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                            </Form.Group>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer style={{justifyContent:"center"}}>
                    <Button variant='danger' onClick={handleDeleteUser}>Delete</Button>
                    <Button variant='success' onClick={handleChangePassword}>Change Password</Button>
                    <Button variant='secondary' onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showAddUserModal} onHide={handleCloseAddUserModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className='mb-3'>
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type='text'
                            value={newUsername}
                            onChange={(e) => setNewUsername(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type='password'
                            value={newUserPassword}
                            onChange={(e) => setNewUserPassword(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer style={{justifyContent:"center"}}>
                    <Button variant='primary' onClick={handleAddNewUser}>Add User</Button>
                    <Button variant='secondary' onClick={handleCloseAddUserModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ChangePass;
