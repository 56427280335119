import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getClientDetails, updateClientDetails } from '../../services/api'; // Import API functions
import { FaFilePdf, FaPlus, FaMinus } from 'react-icons/fa';
import './clients.css';

const ClientDetails = () => {
  const { id } = useParams();
  const [clientData, setClientData] = useState(null);
  const [showFiles, setShowFiles] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // Track edit mode
  const [editData, setEditData] = useState(null); // Track editable data
  const role = localStorage.getItem('role');
  const toggleFiles = () => setShowFiles(!showFiles);


  const fetchClientData = async () => {
    try {
      const response = await getClientDetails(id);
      
      setClientData(response.data);
      setEditData(response.data); // Initialize editable data
    } catch (error) {
      console.error("Error fetching client details:", error);
    }
  };



  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await getClientDetails(id);
        console.log(response.data)
        setClientData(response.data);
        setEditData(response.data); // Initialize editable data
      } catch (error) {
        console.error("Error fetching client details:", error);
      }
    };
    fetchClientData();
  }, [id]);

  const handleEditToggle = () => setIsEditing(!isEditing); // Toggle edit mode

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value }); // Update editable data
  };

  const handleSave = async () => {
    try {
      await updateClientDetails(id, editData); // Call the API to save changes
      setClientData(editData); // Update main data
      setIsEditing(false); // Exit edit mode
      fetchClientData();

    } catch (error) {
      console.error('Error updating client details:', error);
    }
  };

  const handleCancel = () => {
    setEditData(clientData); // Revert to original data
    setIsEditing(false); // Exit edit mode
  };

  if (!clientData) return <p>Loading client details...</p>;

  let filesArray = [];
  if (clientData) {
    filesArray = Array.isArray(clientData.files)
      ? clientData.files
      : JSON.parse(clientData.files || '[]'); // Handle undefined or null files
  }

  return (
    <div className='famielien-form-container' style={{position:"static"}}>
    <div className="client-details magical-bg ">
      <div className="client-info magical-card w-100" style={{maxWidth:"100%"}}>
        <h2>Client Details</h2>

        {isEditing ? (
          <div className="row" style={{    columnGap: "30px"}}>
            {/* Editable Fields for Client Details */}
            <div className='col-md-5 align-items-center gap-2 sec-det'>
              <label><strong>Name:</strong></label>
              <input
              className='p-2'
                type="text"
                name="vornname"
                value={editData.vornname}
                onChange={handleInputChange}
              />
            </div>
            <div className='col-md-5 align-items-center gap-2 sec-det'>
              <label><strong>Nachname:</strong></label>
              <input
              className='p-2'
                type="text"
                name="nachname"
                value={editData.nachname}
                onChange={handleInputChange}
              />
            </div>
            <div className='col-md-5 align-items-center gap-2 sec-det'>
              <label><strong>Geburtstag:</strong></label>
              <input
              className='p-2'
                type="date"
                name="geburtstage"
                value={editData.geburtstage}
                onChange={handleInputChange}
              />
            </div>
            <div className='col-md-5 align-items-center gap-2 sec-det'>
              <label><strong>Mobile:</strong></label>
              <input
              className='p-2'
                type="text"
                name="number"
                value={editData.number}
                onChange={handleInputChange}
              />
            </div>

            {/* Editable Fields for Partner Information */}
            <div className='col-md-5 align-items-center gap-2 sec-det'>
              <label><strong>Partner Vorname:</strong></label>
              <input
              className='p-2'
                type="text"
                name="w_vorname"
                value={editData.w_vorname}
                onChange={handleInputChange}
              />
            </div>
            <div className='col-md-5 align-items-center gap-2 sec-det'>
              <label><strong>Partner Nachname:</strong></label>
              <input
              className='p-2'
                type="text"
                name="w_nachname"
                value={editData.w_nachname}
                onChange={handleInputChange}
              />
            </div>
            <div className='col-md-5 align-items-center gap-2 sec-det'>
              <label><strong>Partner Geburtstag:</strong></label>
              <input
              className='p-2'
                type="date"
                name="w_birthdate"
                value={editData.w_birthdate}
                onChange={handleInputChange}
              />
            </div>
            <div className='col-md-5 align-items-center gap-2 sec-det'>
              <label><strong>P-Aufname Status:</strong></label>
              <select
              className='p-2'
                name="w_aufname"
                value={editData.w_aufname}
                onChange={handleInputChange}
              >
                <option value={true}>Aufname</option>
                <option value={false}>Nicht Aufname</option>
              </select>
            </div>
            <div className='col-md-5 align-items-center gap-2 sec-det'>
              <label><strong>Parter start ab:</strong></label>
              <input
              className='p-2'
                type="date"
                name="w_start_date"
                value={editData.w_start_date}
                onChange={handleInputChange}
              />
            </div>
          </div>
        ) : (
          <div className="row">
            {/* Static Details for Client */}
            <div className='col-md-6 justify-content-start gap-2 sec-det'>
              <p><strong>Name:</strong></p> <p>{clientData.vornname}</p>
            </div>
            <div className='col-md-6 justify-content-start gap-2 sec-det'>
              <p><strong>Nachname:</strong></p><p>{clientData.nachname}</p>
            </div>
            <div className='col-md-6 justify-content-start gap-2 sec-det'>
              <p><strong>Geburtstag:</strong></p><p>{new Date(clientData.geburtstage).toLocaleDateString()}</p>
            </div>
            <div className='col-md-6 justify-content-start gap-2 sec-det'>
              <p><strong>Mobile:</strong></p> <p>{clientData.number}</p>
            </div>

            {/* Static Details for Partner */}
            <div className='col-md-6 justify-content-start gap-2 sec-det'>
              <p><strong>Partner Vorname:</strong></p> <p>{clientData.w_vorname}</p>
            </div>
            <div className='col-md-6 justify-content-start gap-2 sec-det'>
              <p><strong>Partner Nachname:</strong></p> <p>{clientData.w_nachname}</p>
            </div>
            <div className='col-md-6 justify-content-start gap-2 sec-det'>
              <p><strong>Partner Geburtstag:</strong></p> <p>{new Date(clientData.w_birthdate).toLocaleDateString()}</p>
            </div>
            <div className='col-md-6 justify-content-start gap-2 sec-det'>
              <p><strong>Partner Aufname Status:</strong></p> <p className={`${clientData.w_aufname?'class1':'class2'}`}>{clientData.w_aufname ? "Aufname" : "Nicht Aufname"}</p>
            </div>
            <div className='col-md-6 justify-content-start gap-2 sec-det'>
            <p><strong>Partner start ab:</strong></p> <p>{new Date(clientData.w_start_date).toLocaleDateString()}</p>
            </div>

            {/* Static Details for Children */}
            {clientData.kindnum > 0 && (
          <div className='person1'>
            <p><strong>Kinder details:</strong></p>
            {clientData.children.map((child, index) => (<div className='personal-info'>
              <p className='kind-det' key={index}>{child.child_vorname}</p>
              <p className='kind-det' key={index}>{child.child_nachname}</p>
              <p className='kind-det' key={index}>{child.birthdate}</p>
              
              <p className={`kind-det ${child.aufname? 'class1' : 'class2'}`} key={index}>{child.aufname?"aufname":"nicht aufname"}</p>
              {child.start_date!=null && <p className='kind-det' key={index}>{`start:${child.start_date}`}</p>}
              {child.start_date==null && <p className='kind-det' key={index}>{`start:${new Date(clientData.start_date).toLocaleDateString()}`}</p>}
              </div>
            ))}
          </div>
        )}
          </div>
        )}
{(role === 'admin' || role === 'superAdmin') && 
        <div className="edit-buttons">
          {isEditing ? (
            <>
              <button onClick={handleSave} className="save-button">Save</button>
              <button onClick={handleCancel} className="cancel-button">Cancel</button>
            </>
          ) : (
            <button onClick={handleEditToggle} className="rounded edit-button mt-3">Edit</button>
          )}
        </div>}
      </div>

      {filesArray.length > 0 && (
        <div className="files-section magical-card">
          <div className="files-header" onClick={toggleFiles}>
            <h3>Available Files</h3>
            {showFiles ? <FaMinus className="toggle-icon" /> : <FaPlus className="toggle-icon" />}
          </div>
          {showFiles && (
            <div className="files-container">
              {filesArray.map((file, index) => {
                const fileName = file.split('antrags')[1] || `file-${index}`;

                return (
                  <a
                    key={index}
                    href={`https://xn--kunde-sham-direkt-antrge-ccc.de:3000/api/tickets/download/${file.replace(/\\/g, '/')}`}
                    download={fileName || `file-${index}`}
                    className="download-button"
                  >
                    <div className="file-item">
                      <FaFilePdf className="file-icon" />
                      <span className="file-name">{fileName}</span>
                    </div>
                  </a>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
    </div>
  );
};

export default ClientDetails;
