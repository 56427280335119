
    import React, { useEffect, useState } from 'react';
    import './AllClients.css';
    import axios from 'axios';
    import { useNavigate } from 'react-router-dom';
    import { getClientDetails, getClientsByEmployeeId, sendEmail } from '../../services/api';
    import { FaArrowRight } from 'react-icons/fa';
    import Modal from 'react-modal';
    import { faFaceSadTear, faFaceMeh, faFaceSmile } from '@fortawesome/free-regular-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    const ClientsList = () => {
      const [sending, setSending] = useState(false); // Modal state
        const [clients, setClients] = useState([]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState(null);
            const [nameFilter, setnameFilter] = useState('');
        const [displayedCount, setDisplayedCount] = useState(0);
        const [money, setMoney] = useState(0);
        const [animateCount, setAnimateCount] = useState(false);
        const [kindNum, setKindNum] = useState(0);
        const [kindNumOver15, setKindNumOver15] = useState(0);
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [emailList, setEmailList] = useState([{ email: '', files: [] }]);
        const [selectedClient, setSelectedClient] = useState(null);
        const [detailedClient, setDetailedClient] = useState(null);
        const [subject, setSubject] = useState('');
        const [suggestions, setSuggestions] = useState([]);
        const [timeoutId, setTimeoutId] = useState(null);
      const [progress, setProgress] = useState(0);
        const [kundeFilter, setKundeFilter] = useState('');
        const [nachnameFilter, setNachnameFilter] = useState('');
        // const [monthFilter, setMonthFilter] = useState(() => {
        //     const now = new Date();
        //     const year = now.getFullYear();
        //     const month = String(now.getMonth() + 1).padStart(2, '0');
        //     return `${year}-${month}`;
        // });
        const [monthFilter, setMonthFilter] = useState('');
    
        const [sortField, setSortField] = useState('submite_date'); // Default sort field
        const [sortOrder, setSortOrder] = useState('asc'); // Default sort order




        const handleSubjectChange = (e) => {
          if (detailedClient) {
            const familyMembers = [
              {
                name: `${detailedClient?.vornname} ${detailedClient?.nachname} ${detailedClient?.geburtstage ? new Date(detailedClient.geburtstage).toLocaleDateString() : 'N/A'}`,
                type: 'Client',
                birthDate: detailedClient?.geburtstage ? new Date(detailedClient.geburtstage).toLocaleDateString() : 'N/A'
              },
            ];
        
            // Add wife if available
            if (detailedClient?.w_vorname && detailedClient?.w_nachname) {
              familyMembers.push({
                name: `${detailedClient?.w_vorname} ${detailedClient?.w_nachname} ${detailedClient?.w_birthdate ? new Date(detailedClient.w_birthdate).toLocaleDateString() : 'N/A'}`,
                type: 'Wife',
                birthDate: detailedClient?.w_birthdate ? new Date(detailedClient.w_birthdate).toLocaleDateString() : 'N/A'
              });
            }
        
            // Add children if available
            if (detailedClient?.children?.length > 0) {
              familyMembers.push(...detailedClient?.children.map(child => ({
                name: `${child?.child_vorname} ${child?.child_nachname} ${child?.birthdate ? new Date(child.birthdate).toLocaleDateString() : 'N/A'}`,
                type: 'Child',
                birthDate: child?.birthdate ? new Date(child.birthdate).toLocaleDateString() : 'N/A'
              })));
            }
        
            const value = e.target.value;
            setSubject(value);
        
            // Filter family members based on the input value
            const filteredSuggestions = familyMembers.filter(member =>
              member.name.toLowerCase().includes(value.toLowerCase())
            );
        
            setSuggestions(filteredSuggestions);
          }
        };
        
        const formatDateGerman = (date) => {
          if (!date) return 'N/A';
          const d = new Date(date);
          const day = String(d.getDate()).padStart(2, '0');  // Ensure two digits
          const month = String(d.getMonth() + 1).padStart(2, '0'); // Ensure two digits
          const year = d.getFullYear();
          return `${day}.${month}.${year}`;
        };
        
        const handleFocus = () => {
          if (detailedClient) {
            const familyMembers = [
              {
                name: `${detailedClient?.vornname} ${detailedClient?.nachname} ${formatDateGerman(detailedClient?.geburtstage)}`,
                type: 'Client',
                birthDate: formatDateGerman(detailedClient?.geburtstage),
              },
            ];
        
            // Add wife if available
            if (detailedClient?.w_vorname && detailedClient?.w_nachname) {
              familyMembers.push({
                name: `${detailedClient?.w_vorname} ${detailedClient?.w_nachname} ${formatDateGerman(detailedClient?.w_birthdate)}`,
                type: 'Wife',
                birthDate: formatDateGerman(detailedClient?.w_birthdate),
              });
            }
        
            // Add children if available
            if (detailedClient?.children?.length > 0) {
              familyMembers.push(...detailedClient?.children.map(child => ({
                name: `${child?.child_vorname} ${child?.child_nachname} ${formatDateGerman(child?.birthdate)}`,
                type: 'Child',
                birthDate: formatDateGerman(child?.birthdate),
              })));
            }
        
            // Show suggestions even if the user has not typed anything
            setSuggestions(familyMembers);
          }
        };
        
        
        const handleSelectSuggestion = (suggestion) => {
          console.log("name",suggestion.name)
          setSubject(suggestion.name); // Update the input with the selected suggestion's name
           setSuggestions([]);
          if (timeoutId) {
            clearTimeout(timeoutId); // Clear the timeout to prevent suggestions from resetting too early
          }
        };
        
        // Reset suggestions when input loses focus
       // Handle blur with setTimeout
const handleBlur = () => {
  const timeout = setTimeout(() => {
    setSuggestions([]); // Reset suggestions after a short delay
  }, 150); // Delay in ms, can adjust if needed

  setTimeoutId(timeout); // Store the timeout ID so it can be cleared if necessary
};
        
        const handleOpenModal = async (client) => {
          const response=await getClientDetails(client.id);
          console.log("response:",response)
          setDetailedClient(response.data)
            setSelectedClient(client);
            const clientDob = client.geburtstage ? new Date(client.geburtstage).toLocaleDateString() : 'N/A';
            // setSubject(`Kunde :${client.vornname} ${client.nachname} - ${clientDob})`);
            setIsModalOpen(true);
          };
        
          const handleCloseModal = () => {
            setIsModalOpen(false);
            setEmailList([{ email: '', files: [] }]);
            setSelectedClient(null);
            setSubject('');
          };

          const handleAddEmailField = () => {
            setEmailList([...emailList, { email: '', files: [] }]);
          };
        
          const handleRemoveEmailField = (index) => {
            setEmailList(emailList.filter((_, i) => i !== index));
          };
        
          const handleEmailChange = (index, value) => {
            const updatedList = [...emailList];
            updatedList[index].email = value;
            setEmailList(updatedList);
          };
          const currentDate = new Date();
        
          const handleFilesChange = (index, files) => {
            const updatedList = [...emailList];
            updatedList[index].files = Array.from(files); // Convert FileList to an array vertrieb@big-direkt.de
            setEmailList(updatedList);
          };

          const handleSendEmails = async () => {
            try {
              setSending(true) 
              for (const item of emailList) {
                const formData = new FormData();
                formData.append('email', "vertrieb@big-direkt.de");
                formData.append('subject', subject);
                formData.append('clientName', `${selectedClient.vornname} ${selectedClient.nachname}`);
                formData.append('clientDob', selectedClient.geburtstage ? new Date(selectedClient.geburtstage).toLocaleDateString('de-DE') : 'N/A');
                formData.append('clientId', selectedClient.id);
                // Attach all files for this email
                item.files.forEach((file, i) => {
                  formData.append(`files[${i}]`, file);
                });
                const ANTRAG_URL = process.env.REACT_APP_ANTRAG_URL;
                const token = localStorage.getItem('token');
                await axios.post(`${ANTRAG_URL}/send-email`, formData, {
                  onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    setProgress(percent); // Update the progress state
                  },
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
              });}
              setSending(false)
              alert('Emails sent successfully!');
              handleCloseModal();
            } catch (error) {
              setSending(false)
              console.error('Error sending emails:', error);
              alert('Failed to send emails.');
            }
          };
        
        const navigate = useNavigate();
    
        const getAge = (birthdate) => {
            if (!birthdate) return 0;
            const today = new Date();
            const birthDate = new Date(birthdate);
            let age = today.getFullYear() - birthDate.getFullYear();
            const month = today.getMonth();
            if (month < birthDate.getMonth() || (month === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        };
        const getChildInfo = (client) => {
            const childrenArray = Array.isArray(client.children) ? client.children : [];
            const totalChildren = childrenArray.filter(child => child?.birthdate).length;
            const childrenOver15 = childrenArray.filter(child => child?.birthdate && getAge(child.birthdate) > 15);
            const numAufname = childrenArray.filter(child => child?.aufname && child.aufname==true);
            console.log(numAufname.length)
            let totalAuf=numAufname.length+1;
            if(client.w_aufname==true){
                totalAuf=totalAuf+1;
            }
            return {
                totalChildren,
                childrenOver15: childrenOver15.length,
                aufNamen: numAufname.length,
                totalAuf
            };
        };
    
        const increaseAmount = (num) => {
            let startCount = 0;
            const endCount = num;
        
            if (endCount > 0) {
                const duration = 1000; // Animation duration in milliseconds
                const increment = Math.ceil(endCount / (duration / 1)); // Adjust increment based on duration
        
                const interval = setInterval(() => {
                    startCount += increment;
                    if (startCount >= endCount) {
                        startCount = endCount;
                        clearInterval(interval); // Stop when we reach the final count
                    }
                    setDisplayedCount(startCount); // Update the displayed count
                    setMoney(startCount * 20); // Update money count based on filtered Aufträge
                    setAnimateCount(true); // Trigger animation
                    setTimeout(() => setAnimateCount(false), 1000); // Reset animation state
                }, 100); // Run every 100 milliseconds
        
                return () => clearInterval(interval); // Clean up on unmount
            } else {
                setMoney(startCount * 20);
                setDisplayedCount(startCount);
            }
        };
        
        useEffect(() => {
            const filteredClients = getFilteredAntrags(clients);
        
            // Calculate the total amount: 1 for each client, plus 1 for each child older than 15
            let totalAmount = 0;
        
            filteredClients.forEach(client => {
                if(client.w_aufname==true){
                    totalAmount+=1;
                }
                // Add 1 for each client
                totalAmount += 1;
        
                // Add 1 for each child older than 15
                const { aufNamen } = getChildInfo(client); // Get the count of children over 15
                totalAmount += aufNamen; // Add children over 15 count to totalAmount
            });
        
            // Animate the amount
            increaseAmount(totalAmount); // Call the animation function with the calculated totalAmount
        }, [clients, monthFilter, nameFilter]);
        
        
        useEffect(() => {
            const totalChildren = clients.reduce((total, client) => total + getChildInfo(client).totalChildren, 0);
            const totalChildrenOver15 = clients.reduce((total, client) => total + getChildInfo(client).childrenOver15, 0);
            setKindNum(totalChildren);
            setKindNumOver15(totalChildrenOver15);
        }, [clients]);
    
        useEffect(() => {
            getClients();
        }, []);
    
        const getClients = async () => {
            setLoading(true);
            try {
                // Get clients for the current user by user ID stored in localStorage
                const response = await getClientsByEmployeeId(localStorage.getItem('user_id'));
                setClients(response.data);
            } catch (err) {
                setError('Failed to fetch client details. Please check your permissions.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        const getMonthRange = (month) => {
            const date = new Date(month);
            const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
            const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            return { startDate, endDate };
        };
    
        const getFilteredAntrags = (clients) => {
            return clients.filter(client => {
              const matchesKunde = (nameFilter === '' || 
                (client.vornname && client.vornname.toLowerCase().includes(nameFilter.toLowerCase())) || 
                (client.nachname && client.nachname.toLowerCase().includes(nameFilter.toLowerCase()))
            );
               
               
                const { startDate, endDate } = getMonthRange(monthFilter);
                const sentDate = new Date(client.submite_date || "");
                const matchesDateRange = !monthFilter || (sentDate >= startDate && sentDate <= endDate);
                return matchesKunde && matchesKunde && matchesDateRange;
            });
        };
    
        const toggleSortOrder = (field) => {
            if (sortField === field) {
                setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            } else {
                setSortField(field);
                setSortOrder('asc');
            }
        };
    
        const sortAntrags = (clients) => {
            const sortedAntrags = [...clients].sort((a, b) => {
                const dateA = new Date(a[sortField] || "");
                const dateB = new Date(b[sortField] || "");
                return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
            });
            return sortedAntrags;
        };
    
        if (loading) {
            return <div>Loading...</div>;
        }
    
        if (error) {
            return <div>Error: {error}</div>;
        }
    
        const filteredClients = getFilteredAntrags(clients);
        const sortedClients = sortAntrags(filteredClients);
    
        return (
            <div className='famielien-form-container col-md-10'>
                <div className="my-antrags-container col-md-12">
                    <h2>Alle Kunden</h2>
                    {clients.length === 0 ? (
                        <p>No clients found.</p>
                    ) : (
                        
                        <div>
                            <div className="counter-wrapper">
        {/* Display the animated Aufträge count */}
        {/* <p className={`digital-counter ${animateCount ? 'digital-counter-animate' : ''}`}>
            Num of Aufträge: {displayedCount}
        </p>
     */}
        {/* Arrow between the two counters */}
        {/* <FaArrowRight className="arrow" />
    
       
        <p className={`digital-counter ${animateCount ? 'digital-counter-animate' : ''}`}>
            Provision: {money} €
        </p> */}
    </div>
    
                            {/* Filter Inputs and Sorting */}
                            <div className="filter-card">
                                <div className="filters">
                              
                                    <input
                                        type="text"
                                        className="filter-input"
                                        placeholder="Filter by Kunden Name"
                                        value={nameFilter}
                                        onChange={(e) => setnameFilter(e.target.value)}
                                    />
                                    <input
                                        type="month"
                                        className="filter-input"
                                        value={monthFilter}
                                        onChange={(e) => setMonthFilter(e.target.value)}
                                    />
                                </div>
                            </div>
    
                            <div className='table-wrapper1'>
                                <table className="antrags-table">
                                    <thead>
                                        <tr>
                                        <th>Vorname</th>
                                        <th>Nachname</th>
                                        <th>Geburtstag</th>
                                        <th>start datum</th>
                                        <th>Kinder</th>
                                        <th>Aufnamen</th>
                                        <th>Mobile</th>
                                      
                                        <th>Status</th>
                                        
                                        <th onClick={() => toggleSortOrder('submite_date')}>
                                            Sent Date {sortField === 'submite_date' && (sortOrder === 'asc' ? '↑' : '↓')}
                                        </th>
                                        <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sortedClients.map((client) => {
                                            const { totalChildren, childrenOver15, totalAuf } = getChildInfo(client);
                                            return (
                                                <tr key={client.id} onClick={() => navigate(`/client-details/${client.id}`)}>
                                                  
                                                    <td>{client.vornname}</td>
                                                    <td>{client.nachname}</td>
                                                    <td>{client.geburtstage ? new Date(client.geburtstage).toLocaleDateString() : 'N/A'}</td>
                                                    <td>{client.start_date ? new Date(client.start_date).toLocaleDateString() : 'N/A'}</td>
                                                    <td>{totalChildren}</td>
                                                    <td>{totalAuf}</td>
                                                    <td>{client.number}</td>
                                                    <td>
      {(client.status === 0 && new Date(client.start_date) <= currentDate) && (
   <FontAwesomeIcon icon={faFaceSmile} style={{ color: 'green' }} size="lg" />
      )}
      {(client.status === 0 && new Date(client.start_date) > currentDate ) && (
        <FontAwesomeIcon icon={faFaceSadTear} style={{ color: 'red' }} size="lg" />
      )}
      {(client.status === 1 && new Date(client.start_date) > currentDate) &&  (
        <FontAwesomeIcon icon={faFaceMeh} style={{ color: 'orange' }} size="lg" />
      )}
      {(client.status === 1 && new Date(client.start_date) <= currentDate) &&  (
        <FontAwesomeIcon icon={faFaceSmile} style={{ color: 'green' }} size="lg" />
      )}
     
    </td>
                                                    <td>{client.submite_date ? new Date(client.submite_date).toLocaleDateString() : 'N/A'}</td>
                                                    <td>
                  <button style={{borderRadius:"10px", padding:"3px"}} onClick={(e) => { e.stopPropagation(); handleOpenModal(client); }}>
                  <i className="bi bi-envelope"></i> 

                  </button>
                </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {detailedClient && <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} ariaHideApp={false}>
      <div style={{ padding: '20px', maxWidth: '500px', margin: 'auto',top: "21px",
    width: "75%",
    left: "25%",
    height: "100%",
    border: "10px solid rgb(77 137 213)",
    borderRadius:"15px",
    overflow:"auto"
 }}>
        <h2 style={{ marginBottom: '10px' }}>email senden</h2>
        <p style={{fontSize:"17px"}}>
          <strong>Kunde:</strong> {detailedClient?.vornname} {detailedClient?.nachname} <br />
          <strong>Geburtstag:</strong> {detailedClient?.geburtstage ? new Date(detailedClient.geburtstage).toLocaleDateString() : 'N/A'}
        </p>
        <hr style={{ margin: '10px 0' }} />

        {/* Subject Field */}
        <div>
    <input
    style={{padding:"7px"}}
      type="text"
      value={subject}
      onChange={handleSubjectChange}
      onFocus={handleFocus}
      onBlur={handleBlur} // Reset suggestions on blur
      placeholder="Suche nach einem Familienmitglied"
    />
    <ul>
      {suggestions.map((suggestion, index) => (
        <li 
          key={index} 
          onClick={() => {handleSelectSuggestion(suggestion)}} // Select suggestion
        >
          {suggestion.name}
        </li>
      ))}
    </ul>
  </div>
        {emailList.map((item, index) => (
          <div key={index} style={{ marginBottom: '20px' }}>
            {/* Email Field */}
            {/* <label style={{ display: 'block', marginBottom: '5px' }}>
              <strong>Email {index + 1}</strong>
            </label>
            <input
              type="email"
              placeholder="Recipient Email"
              value={item.email}
              onChange={(e) => handleEmailChange(index, e.target.value)}
              style={{
                width: '100%',
                padding: '8px',
                marginBottom: '10px',
                border: '1px solid #ccc',
                borderRadius: '4px',
              }}
              required
            /> */}

            {/* File Attachments */}
            <label style={{ display: 'block', marginBottom: '5px' }}>
              <strong>Dateien anhängen</strong>
            </label>
            <input
              type="file"
              multiple
              onChange={(e) => handleFilesChange(index, e.target.files)}
              style={{ marginBottom: '10px' ,padding:"7px"}}
            />

            {/* Remove Email Field */}
            {emailList.length > 1 && (
              <button
                onClick={() => handleRemoveEmailField(index)}
                style={{
                  backgroundColor: '#f44336',
                  color: 'white',
                  border: 'none',
                  padding: '5px 10px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                Remove
              </button>
            )}
          </div>
        ))}

        {/* Add Email Button */}
        {/* <button
          onClick={handleAddEmailField}
          style={{
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            padding: '10px',
            borderRadius: '4px',
            cursor: 'pointer',
            marginBottom: '20px',
          }}
        >
          + Add Another Email
        </button> */}

        {/* Action Buttons */}
        {sending && <div style={{ marginTop: '20px' }}>
      <div style={{ fontWeight: 'bold' }}>Sending Email...</div>
      <div style={{ width: '100%', height: '10px', backgroundColor: '#ccc', marginBottom:"30px" }}>
        <div
          style={{
            width: `${progress}%`,
            height: '100%',
            backgroundColor: 'green',
            transition: 'width 0.1s ease-in-out',
          }}
        ></div>
      </div>
    </div>}
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <button
            onClick={handleSendEmails}
            style={{
              width:"30%",
              backgroundColor: '#2196F3',
              color: 'white',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Send
          </button>
          <button
            onClick={handleCloseModal}
            style={{
              width:"30%",
              backgroundColor: '#ccc',
              color: '#333',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };
    
    export default ClientsList;
    